<template>
  <div class="box f_color1 font2">
    <van-nav-bar title="分成统计" left-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <van-tabs v-model:active="active" @change="onTabChanged">
      <van-tab v-for="(tab, i) in tabs" :key="i" :title="tab.name">
        <van-search class="search" v-model="tab.search" @search="onSearch" shape="round" show-action
          placeholder="输入用户ID">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
        <div class="search-type">
          <van-radio-group v-model="tab.checked" direction="horizontal">
            <van-radio name="0" icon-size="14px" class="font1">用户ID</van-radio>
            <van-radio name="1" icon-size="14px" class="font1">上级ID</van-radio>
          </van-radio-group>
        </div>
        <!--            <van-pull-refresh v-model="tab.refreshing" @refresh="onRefresh">-->
        <van-list class="list font2" v-model:loading="tab.loading" :immediate-check="false" :finished="tab.finished"
          finished-text="没有更多了" @load="onLoad">
          <div class="listItem" v-for="(li, i) in tab.list" :key="i">
            <div class="line1">
              <div class="time">{{ getDisplayDate(li) }}</div>
              <div class="state">{{ li.state == 0 ? "未结算" : li.state == 1 ? "可结算" : "已结算" }}</div>
            </div>
            <div class="line2">
              <div class="name">{{ li.userInfo.nickName }}</div>
              <div class="income">{{ li.num }}</div>
            </div>
            <div class="line3">
              <div class="uid">ID: {{ li.userId }}</div>
              <div class="desc">{{ li.type == 0 ? "组长分成" : "成员分成" }}</div>
            </div>
          </div>
        </van-list>
        <!--            </van-pull-refresh>-->
      </van-tab>
    </van-tabs>
    <Mask v-if="marke"></Mask>
  </div>
</template>
<script>
// import { wxqq } from '../utils/utils'
import Mask from '../components/Mask'
import routerUtil from '../utils/routerUtil'
import { Toast } from "vant";

export default {
  name: 'Comission',
  data() {
    return {
      groupInfo: null,
      active: 0,
      tabs: [
        {
          name: '本周期',
          search: '',
          searchUid: null,
          checked: '0',
          searchType: 0,
          loading: false,
          finished: false,
          refreshing: false,
          list: [],
        },
        {
          name: '历史周期',
          search: '',
          searchUid: null,
          checked: '0',
          searchType: 0,
          loading: false,
          finished: false,
          refreshing: false,
          list: [],
        },
      ],
      marke: false,
    }
  },
  components: {
    Mask,
  },
  mounted() {
    if (this.$route.params.userId) {
      this.search = this.$route.params.userId
      this.searchUid = parseInt(this.search);
    }
    this.reqInfo()
    // this.marke = wxqq()
  },
  methods: {
    reqInfo() {
      this.$request.postJson('/api/user/pay_group/info', {})
        .then((res) => {
          if (res.data.code == 0) {
            this.groupInfo = res.data.data;
            if (!this.groupInfo) {
              Toast('暂无推广组信息');
              routerUtil.back()
              return;
            }
            this.getList()
          }
        })
    },
    getList(type) {
      const tab = this.tabs[this.active];
      let lastId = ''
      if (type && tab.list.length > 0) {
        lastId = tab.list[tab.list.length - 1].id
      }
      this.$request.postJson('/api/user/pay_group/share_logs', {
        groupId: this.groupInfo.id,
        periodType: this.active,
        userType: tab.searchType,
        userId: tab.searchUid == null ? 0 : tab.searchUid,
        lastId: lastId,
        pageSize: 15
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.logs.length == 0) {
            tab.loading = false;
            tab.refreshing = false
            tab.finished = true;
            return
          }
          const uids = [];
          for (let log of res.data.data.logs) {
            uids.push(log.userId);
          }
          this.$request.post('/api/user/info/userInfo', { userIds: JSON.stringify(uids) })
            .then((res1) => {
              tab.loading = false;
              tab.refreshing = false
              if (res1.data.code == 0) {
                const userMap = new Map();
                for (let user of res1.data.data.users) {
                  if (user.code == 0) {
                    userMap.set(user.userId, user);
                  } else {
                    Toast("请求用户基本信息失败")
                    return;
                  }
                }
                for (let log of res.data.data.logs) {
                  log.userInfo = userMap.get(log.userId);
                }
                tab.list.push(...res.data.data.logs)
                tab.finished = res.data.data.logs.length < 15
              }
            })
        }
      })
    },
    onClickLeft() {
      routerUtil.back()
    },
    onClickRight() {
    },
    onTabChanged(name) {
      let tab;
      if (name === '本周期') {
        tab = this.tabs[0]
      } else {
        tab = this.tabs[1]
      }
      if (!tab.loading && !tab.finished && tab.list.length === 0) {
        this.getList()
      }
    },
    onSearch() {
      const tab = this.tabs[this.active];
      if (tab.search == "") {
        tab.searchUid = null
      } else {
        if (isNaN(Number(tab.search, 10))) {
          Toast("请输入用户ID")
          return
        }
        const uid = parseInt(tab.search);
        tab.searchUid = uid;
      }
      tab.searchType = parseInt(tab.checked)
      tab.list = []
      this.onRefresh()
    },
    onLoad() {
      this.getList(true)
    },
    onRefresh() {
      const tab = this.tabs[this.active];
      // 清空列表数据
      tab.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      tab.loading = true;
      this.getList()
    },
    getDisplayDate(li) {
      const dateBegin = new Date(li.startTime);
      const monthBegin = dateBegin.getMonth() + 1;
      const dayBegin = dateBegin.getDate();
      const dateEnd = new Date(li.endTime);
      const monthEnd = dateEnd.getMonth() + 1;
      const dayEnd = dateEnd.getDate();
      return `${dateBegin.getFullYear()}/${monthBegin > 9 ? monthBegin : '0' + monthBegin}/${dayBegin > 9 ? dayBegin : '0' + dayBegin} 至 ${dateEnd.getFullYear()}/${monthEnd > 9 ? monthEnd : '0' + monthEnd}/${dayEnd > 9 ? dayEnd : '0' + dayEnd}`;
    }
  },
}
</script>
<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;

  /deep/ .van-tabs__line {
    background: #2369f6;
  }

  .search {
    padding-bottom: 0;
  }

  .search-type {
    height: 20px;
    padding: 10px 12px;
  }
}

.list {
  position: absolute;
  width: 100%;
  background-color: #f6f6f6;

  .listItem {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    background-color: #fff;

    .line1 {
      width: 100%;
      height: 30px;

      .time {
        display: inline-block;
        padding-left: 12px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #787878;
      }

      .state {
        float: right;
        padding-right: 12px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #787878;
      }
    }

    .line2 {
      width: 100%;
      height: 40px;

      .name {
        display: inline-block;
        padding-left: 12px;
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        color: #000;
      }

      .income {
        float: right;
        padding-right: 12px;
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        color: #000;
      }
    }

    .line3 {
      width: 100%;
      height: 30px;

      .uid {
        display: inline-block;
        padding-left: 12px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #787878;
      }

      .desc {
        float: right;
        padding-right: 12px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #f29a38;
      }
    }
  }
}

.van-pull-refresh {
  height: 100% !important;
  overflow: auto !important;
}

.van-list {
  height: auto !important;
}
</style>
